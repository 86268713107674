import settings from "./configs/config_settings.json";

import { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { useState } from "react";

import SignUpBar from "./components/Home/SignUpBar/SignUpBar";
import GuidesBar from "./components/Home/GuidesBar/GuidesBar";
import LatestAssets from "./components/Assets/LatestAssets/LatestAssets";
import TrendingAssets from "./components/Assets/TrendingAssets/TrendingAssets";
//import TrendingSerie from "./components/Series/TrendingSerie/TrendingSerie";
import Serie from "./components/Series/Serie/Serie";
import VideoDetails from "./components/Assets/VideoDetails/VideoDetails/VideoDetails";
import PromoSignup from "./components/Promos/PromoSignup";
import PurchaseFlow from "./components/Packages/PurchaseFlow";
import LoggedInBox from "./components/Packages/LoggedInBox/LoggedInBox";
import Navbar from "./components/navigation/Navbar";
import PromoBanner from "./components/PromoBanner/PromoBanner";
import LoginModal from "./components/modal/LoginModal";
import Footer from "./components/Footer/Footer";
import PrivacyTerms from "./components/PrivacyTerms/PrivacyTerms";
import ContactUs from "./components/ContactUs/ContactUs";
import Loader from "./components/Common/Loader/Loader";
import Banner from "./components/Banner/Banner";
import HelmetMetaData from "./components/HelmetMetaData";
import FAQFi from "./components/FAQ/FAQFi";
import FAQEn from "./components/FAQ/FAQEn";
import PrivacyTermsEn from "./components/PrivacyTerms/PrivacyTermsEn";
import TOSEn from "./components/TOS/TOSEn";

import Tos from "./pages/tos";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import Search from "./pages/Search";
import Series from "./pages/Series";
import Video from "./pages/Video";
import Profile from "./pages/Profile";
import Assets from "./pages/Assets";
import AllSeries from "./pages/AllSeries";
import Subscription from "./pages/Subscription";
import ForgotPassword from "./pages/ForgotPassword";
import AssetCategories from "./pages/AssetCategories";
import ChangePassword from "./pages/ChangePassword";

import { ROUTES } from "./constants/route";
import { Promos } from "./components/Promos/Promos/Promos";
import { useMyContext } from "./contexts/StateHolder";
import { UserDeleteModal } from "./components/modal/UserDeleteModal";

function App() {
    const { user, setUser, setUserLoggedIn, setLanguageCode, userLoggedIn, setLanguage } = useMyContext();
    const [cookies] = useCookies("");
    const { i18n } = useTranslation();

    const [isInitialLoaded, setIsInitialLoaded] = useState(false);

    useEffect(() => {
        if (cookies?.user) {
            let cookiesUser = { ...cookies?.user };
            setUser(cookiesUser);

            setUserLoggedIn(true);
        }
    }, [cookies?.user, setUser, setUserLoggedIn]);

    useEffect(() => {
        if (cookies?.ln) {
            setLanguageCode(cookies?.ln);
            if (cookies?.ln === "en") {
                setLanguage("en_US");
            } else {
                setLanguage("fi_FI");
            }

            i18n.changeLanguage(cookies?.ln);
            setIsInitialLoaded(true);
        } else {
            setIsInitialLoaded(true);
        }
    }, [cookies?.ln, i18n, setLanguageCode, setLanguage]);

    //console.log("user", user);

    return isInitialLoaded ? (
        <>
            <HelmetMetaData image={"https://d15n7gkgywbuz.cloudfront.net/kotikino/share/kotikino.jpg"} title={"Kotikino"} description={"Kotikino"} />
            <Switch>
                <Route path={`${ROUTES.VIDEO}/:orgId/:asset`} exact>
                    <Video />
                </Route>
                <Route>
                    <Navbar />
                    <LoginModal />
                    <UserDeleteModal />

                    <Route path="/" exact>
                        <div>
                            {!userLoggedIn && (
                                <>
                                    <SignUpBar routes={settings.routes} />
                                    <GuidesBar routes={settings.routes} />
                                    <PromoSignup settings={settings.components.frontPagePromo_01} />
                                </>
                            )}
                            <Promos settings={settings.components.frontPagePromo_01} />

                            <div className="container">
                                <Assets settings={settings.components.frontPageCategories_01} />
                                <PromoBanner settings={settings.components.promoBanner} index={0} />
                                <Assets settings={settings.components.frontPageCategories_02} />

                                <LatestAssets />

                                <TrendingAssets title={"trending.comedy"} settings={settings.components.trendingComedy} />
                                <PromoBanner settings={settings.components.promoBanner} index={1} />

                                {/*<TrendingSerie />*/}
                                <Serie />
                                <PromoBanner settings={settings.components.promoBanner} index={2} />
                                <Assets settings={settings.components.frontPageCategories_03} />
                            </div>
                            {userLoggedIn && (
                                <div className="mt-20">
                                    <GuidesBar routes={settings.routes} />
                                </div>
                            )}
                        </div>
                    </Route>
                    {/* ROUTE FOR TVOD VIEW*/}
                    <Route path={ROUTES.TVOD} exact>
                        <Banner settings={settings.components.tvodBanner_01} />
                        <div className="mt-5">
                            <Promos settings={settings.components.tvodPromo_01} />
                        </div>

                        <div className="container">
                            <Assets settings={settings.components.tvodCategories_01} />

                            <PromoBanner settings={settings.components.promoBannerTVOD} index={0} />

                            <Assets settings={settings.components.tvodCategories_02} />

                            <PromoBanner settings={settings.components.promoBannerTVOD} index={1} />

                            <Assets settings={settings.components.tvodCategories_03} />

                            <PromoBanner settings={settings.components.promoBannerTVOD} index={2} />

                            <Assets settings={settings.components.tvodCategories_04} />
                        </div>
                    </Route>

                    <Route path={ROUTES.SVOD} exact>
                        <Banner settings={settings.components.svodBanner_01} />
                        <div className="mt-5">
                            <Promos settings={settings.components.svodPromo_01} />
                        </div>

                        <div className="container">
                            <Assets settings={settings.components.svodCategories_01} />
                            <PromoBanner settings={settings.components.promoBannerSVOD} index={0} />

                            <Assets settings={settings.components.svodCategories_02} />
                            <PromoBanner settings={settings.components.promoBannerSVOD} index={1} />

                            <Assets settings={settings.components.svodCategories_03} />
                            <PromoBanner settings={settings.components.promoBannerSVOD} index={2} />

                            <Assets settings={settings.components.svodCategories_04} />
                        </div>
                    </Route>
                    {/* ROUTE FOR TVOD VIDEO */}
                    <Route path={`${ROUTES.VIDEO_DETAILS}/:orgId/:asset`} exact>
                        <VideoDetails />
                    </Route>

                    <Route path={ROUTES.SERIES} exact>
                        <div className="secondary-container">
                            <AllSeries />
                        </div>
                    </Route>

                    <Route path={`${ROUTES.SERIES_CATEGORIES}/:orgId/:seriesId/:seasonId?`} exact>
                        <Series />
                    </Route>

                    <Route path={ROUTES.LOGIN} exact>
                        <Login />
                    </Route>
                    <Route path={ROUTES.SIGNUP} exact>
                        <Signup />
                    </Route>
                    <Route path={ROUTES.FORGET_PASSWORD} exact>
                        <ForgotPassword />
                    </Route>
                    {/* <Route
          path={`${ROUTES.KATSO_CATEGORIES}/:catTitle/:orgId/:catId`}
          exact
        >
          <div className="secondary-container">
            <AssetCategories assetProperty="SVOD" />
          </div>
        </Route>
        <Route
          path={`${ROUTES.VUOKRAAMO_CATEGORIES}/:catTitle/:orgId/:catId`}
          exact
        >
          <div className="secondary-container">
            <AssetCategories assetProperty="TVOD" />
          </div>
        </Route> */}

                    <Route path={`${ROUTES.CATEGORIES}/:catTitle/:orgId/:catId`} exact>
                        <div className="secondary-container">
                            <AssetCategories />
                        </div>
                    </Route>
                    <Route path={ROUTES.SEARCH} exact>
                        <div className="secondary-container">
                            <Search />
                        </div>
                    </Route>
                    <Route path={`${ROUTES.PACKAGES}/:itemType?/:itemId?`} exact>
                        <LoggedInBox user={user} />

                        <PurchaseFlow settings={settings.components.purchaseflow} />
                    </Route>

                    <Route path={ROUTES.TOS}>
                        <Tos />
                    </Route>

                    <Route path={`/en${ROUTES.TOS}`}>
                        <TOSEn />
                    </Route>

                    <Route path={ROUTES.PROFILE}>
                        <Profile />
                    </Route>

                    <Route path={ROUTES.CHANGE_PASSWORD}>
                        <ChangePassword />
                    </Route>

                    <Route path={ROUTES.SUBSCRIPTION}>
                        <Subscription />
                    </Route>
                    <Route path={ROUTES.PRIVACY_POLICY}>
                        <PrivacyTerms />
                    </Route>
                    <Route path={`/en${ROUTES.PRIVACY_POLICY}`}>
                        <PrivacyTermsEn />
                    </Route>
                    <Route path={`${ROUTES.FAQ}`}>
                        <FAQFi />
                    </Route>
                    <Route path={`/en${ROUTES.FAQ}`}>
                        <FAQEn />
                    </Route>
                    <Route path={ROUTES.CONTACT}>
                        <ContactUs />
                    </Route>
                    <Footer />
                </Route>
            </Switch>
        </>
    ) : (
        <div className="display-flex-center loader">
            <Loader />
        </div>
    );
}

export default App;
