import Button from "../Common/Button/Button";
import { Input } from "../Common/Input/Input";
import { VALIDATOR_EMAIL, VALIDATOR_REQUIRE } from "../Common/Validation/Validator";
import Translations from "../Translations";

import classes from "./Form.module.css";

// THIRD PARTY IMPORT

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faEnvelope, faUser } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const SignupForm = ({
	formSubmitHandler,
	state,
	InputHandler,
	loading,
	isTermsAccepted,
	setIsTermsAccepted,
	backgroundImage,
}) => {
	return (
		<div className={`${classes.authContainer} `}>
			<div
				className={classes.backgroundImage}
				style={{
					backgroundImage: `linear-gradient(190deg, rgba(255, 255, 255, 0.25) 0%,
    rgba(255, 255, 255, 0.29) 67%,rgba(0, 0, 0, 0.5) 100%),
    url(${backgroundImage})`,
				}}
			/>
			<form className={`${classes.authFormContainer} `} onSubmit={formSubmitHandler}>
				<h2 className={`${classes.formHeaderSignUp} font-700  `}>
					<Translations text="form.register.register" />
				</h2>

				<Input
					id="FIRSTNAME"
					label={<Translations text="form.profile.firstName" />}
					placeholder={<Translations text="form.profile.enterFirstName" />}
					type="text"
					element="input"
					validators={[VALIDATOR_REQUIRE()]}
					errorText={<Translations text="form.profile.enterFirstName" />}
					onInput={InputHandler}
					iconName={<FontAwesomeIcon icon={faUser} />}
				/>
				<Input
					id="LASTNAME"
					label={<Translations text="form.profile.lastName" />}
					placeholder={<Translations text="form.profile.enterlastName" />}
					type="text"
					element="input"
					validators={[VALIDATOR_REQUIRE()]}
					errorText={<Translations text="form.profile.enterLastName" />}
					onInput={InputHandler}
					iconName={<FontAwesomeIcon icon={faUser} />}
				/>

				<Input
					id="EMAIL"
					label={<Translations text="form.password.email" />}
					placeholder={<Translations text="form.password.enterEmail" />}
					type="text"
					element="input"
					validators={[VALIDATOR_EMAIL(), VALIDATOR_REQUIRE()]}
					errorText={<Translations text="form.password.enterValidEmail" />}
					onInput={InputHandler}
					iconName={<FontAwesomeIcon icon={faEnvelope} />}
				/>

				<Input
					id="PASSWORD"
					label={<Translations text="form.password.password" />}
					placeholder={<Translations text="form.password.enterPassword" />}
					errorText={<Translations text="form.password.passwordRequired" />}
					type="Password"
					element="input"
					validators={[VALIDATOR_REQUIRE()]}
					onInput={InputHandler}
					iconName={<FontAwesomeIcon icon={faLock} />}
				/>
				<Input
					id="PASSWORD_CONFIRM"
					label={<Translations text="form.password.passwordConfirm" />}
					placeholder={<Translations text="form.password.enterPasswordAgain" />}
					errorText={<Translations text="form.password.passwordMismatch" />}
					type="Password"
					element="input"
					validators={[VALIDATOR_REQUIRE()]}
					// validators={[VALIDATOR_PASSWORDMATCH(state.inputs.PASSWORD.value, state.inputs.PASSWORD_CONFIRM.value)]}
					onInput={InputHandler}
					iconName={<FontAwesomeIcon icon={faLock} />}
				/>

				<div className={`${classes.loginRow} font-100`}>
					<label className={classes.termsaccepted}>
						<input
							type="checkbox"
							onChange={() => setIsTermsAccepted((prev) => !prev)}
							checked={isTermsAccepted}
						/>
						<Link to={`/tos`}>
							<Translations text="signUpForm.agreeOnTerms" />
						</Link>
					</label>
				</div>

				<Button
					className={`${classes.loginFormButton} width-100`}
					disabled={!state.isValid || loading || !isTermsAccepted}
					formButton
				>
					{loading ? (
						<Translations text="shared.loading" />
					) : (
						<Translations text="form.register.register" />
					)}
				</Button>

				{
					<div className={`${classes.linkContainer} font-200 `}>
						<div>
							<Translations text="form.password.alreadyHaveAccount" />
							<Link to={`/auth/login`} className={classes.linkText}>
								<Translations text="shared.login" />
							</Link>
						</div>
						<div>
							<Translations text="form.password.forgotPassword" />
							<Link to={`/auth/forget-password`} className={classes.linkText}>
								<Translations text="shared.clickHere" />
							</Link>
						</div>
					</div>
				}
			</form>
		</div>
	);
};

export default SignupForm;
